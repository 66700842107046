import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import AddFindingModal from './AddFindingModal';
import CompleteFindingsModal from './CompleteFindingsModal';
import AddNotesModal from './AddNotesModal';
import FindingDetail from './FindingDetail';
import FindingListing from './FindingListing';
import SuccessNotification from '../../Shared/SuccessNotification/SuccessNotification';
import RolesContext from '../../../contexts/RolesContext';
import DashboardContext from '../../../contexts/DashboardContext';
import InspectionContext from '../../../contexts/InspectionContext';
import { ActionButtons } from '../../Shared/ActionButtons';
import SelectAll from '../../Shared/Selection/SelectAll';
import SelectionStatus from '../../Shared/Selection/SelectionStatus';
import { LoadingMessage } from '@sw-sw/lib-ui';
import PrintModal from '../../Shared/Print/PrintModal';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import {
  FormContext,
  FormSchemaFields,
  FormContextProvider,
} from '@sw-sw/lib-form';
const controlField = 'findingFilter';

const FindingFilter = (props: any) => {
  const formContext = useContext(FormContext);

  const schema = {
    [controlField]: {
      controlType: UIControlType.select,
      placeholder: 'Finding Types',
      className: 'filter small',
      options: [{ label: 'All Findings', value: 'all' }, ...props.options],
    },
  };

  return (
    <FormSchemaFields
      className='filter-form'
      formData={formContext.value}
      // initialFormData={{ [controlField]: 'all' }}
      onChange={(field, val) => {
        formContext.set(field, val);
        props.handleChange(val);
      }}
      schema={schema}
    />
  );
};

const Findings: React.FC<{}> = () => {
  const location = useLocation<{ checkAll: boolean }>();
  const history = useHistory();
  const inspectionContext = useContext(InspectionContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const { inspectionsCertDue, fetchStatsFuncForOF } = useContext(DashboardContext);
  const isPublic = location.pathname.startsWith("/public")



  if (!inspectionContext.inspection.id) {
    return <LoadingMessage what='Findings' />;
  }

  const {
    findings,
    setFindings,
    selectedFindings,
    bulkAddNotes,
    selectAllFindings,
    allSelected,
    setAllSelected,
    setSelectedFindings,
  } = inspectionContext;

  const inspectionId = inspectionContext.inspection.id
  const defaultCreatedDate = inspectionContext.getDefaultFindingDate();

  const [showNewFindingModal, setShowNewFindingModal] =
    useState<boolean>(false);
  const [showBulkCompleteModal, setShowBulkCompleteModal] =
    useState<boolean>(false);
  const [showBulkAddNotesModal, setShowBulkAddNotesModal] =
    useState<boolean>(false);
  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<number>(-1);
  const [defaultChecks, setDefaultChecks] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>('all');

  useEffect(() => {
    return () => {
      if (timeoutId > -1) {
        window.clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (inspectionContext.findings) {
      inspectionContext.setFilteredFindings(
        filterFindings(inspectionContext.findings, filter),
      );
    }
  }, [inspectionContext.findings, filter]);

  const filterFindings = (input: any[], filterParam: string) => {
    return input.filter((finding: any) => {
      if (filter === 'all') {
        return true;
      }

      return finding.type === filterParam;
    });
  };

  const getOptions = useMemo(() => {
    if (!findings) return [];

    //get all unique types
    const uniqueTypes = findings.reduce((acc: any, finding: any) => {
      if (!acc.includes(finding.type)) {
        acc.push(finding.type);
      }

      return acc;
    }, []);

    const options = uniqueTypes.sort().map((type: any) => {
      return {
        label:
          type === 'CA'
            ? 'Corrective Action'
            : type === 'A'
              ? 'Achievement'
              : type === 'MI'
                ? 'Maintenance Item'
                : type,
        value: type,
      };
    });

    return options;
  }, [findings]);

  const onAddFinding = (data: any) => {
    if (data.action_note_id) {
      data.createdNow = true
    }
    setFindings([...findings, data]);
  };

  const showSuccessAnimation = () => {
    // show save animation
    const newTimeoutId = window.setTimeout(() => {
      setShowSuccessNotification(false);
    }, 2000);

    setShowSuccessNotification(true);
    setTimeoutId(newTimeoutId);
  };

  const listingView = () => {
    setSelectedFindings([]);
  };

  const toggleModal = (target: any, isVisible: boolean) => {
    setShowBulkCompleteModal(false);
    setShowNewFindingModal(false);
    setShowBulkAddNotesModal(false);

    switch (target) {
      case 'showBulkCompleteModal':
        setShowBulkCompleteModal(isVisible);
        break;
      case 'showBulkAddNotesModal':
        setShowBulkAddNotesModal(isVisible);
        break;
      case 'showNewFindingModal':
        setShowNewFindingModal(isVisible);
        break;
      case 'showPrintModal':
        setShowPrintModal(isVisible);
        break;
      default:
        break;
    }
  };

  const handleSelectAll = () => {
    selectAllFindings();
  };

  const readOnlyFindings = inspectionContext.readOnlyFindings || isPublic;
  const readOnlyInspection = inspectionContext.readOnlyInspection || isPublic;

  const hasIncompleteFindings =
    findings.filter(
      (finding: { date_completed: Date }) => !finding.date_completed,
    ).length > 0;

  const hasSelectedFindings = selectedFindings.length > 0;

  if (
    location.state &&
    location.state.checkAll &&
    inspectionContext.inspectionLoaded > 0 &&
    defaultChecks
  ) {
    inspectionContext.handleDefaultChecks(findings);
    setDefaultChecks(false);
  }

  return (
    <>
      <Switch>
        <Route path={isPublic ? '/public/inspection/:inspectionId/findings/:findingId/details' : `/inspection/:inspectionId/findings/:findingId/details`}>
          <FindingDetail
            onBackClick={listingView}
            onSubmit={showSuccessAnimation}
            onDelete={() => {
              // setSelectedFinding(null);
              history.push(isPublic ? `/public/inspection/${inspectionId}/findings` : `/inspection/${inspectionId}/findings`);
            }}
          />
        </Route>

        <Route path='/'>
          <div className='inspection-findings'>
            {!readOnlyFindings && (
              <div className='controls'>
                {hasIncompleteFindings && (
                  <div className='selection-controls'>
                    <SelectAll
                      onSelectAll={handleSelectAll}
                      value={allSelected}
                    ></SelectAll>
                    <SelectionStatus
                      current={selectedFindings.length}
                      total={findings.length}
                      noun='finding'
                      onReset={() => {
                        setAllSelected(false);
                        setSelectedFindings([]);
                      }}
                    />
                  </div>
                )}
                <div className='right-aligned'>
                  <FormContextProvider>
                    {inspectionContext.findings.length > 0 && (
                      <FindingFilter
                        handleChange={setFilter}
                        options={getOptions}
                      />
                    )}
                  </FormContextProvider>
                </div>

                {/* Selection actions */}
                {permCheck('update', 'Close Findings') &&
                  hasSelectedFindings && (
                    <ActionButtons
                      primary={
                        'Complete Finding' +
                        (selectedFindings.length > 1 ? 's' : '')
                      }
                      onClick={() =>
                        Promise.resolve(
                          toggleModal('showBulkCompleteModal', true),
                        )
                      }
                      secondary={[
                        {
                          label: 'Add Notes',
                          icon: 'plus',
                          onClick: () =>
                            toggleModal('showBulkAddNotesModal', true),
                        },
                      ]}
                    />
                  )}

                {/* Default actions */}
                {!hasSelectedFindings && (
                  <ActionButtons
                    primary={
                      !readOnlyInspection && permCheck('create', 'Findings')
                        ? 'Add Finding'
                        : undefined
                    }
                    onClick={() => toggleModal('showNewFindingModal', true)}
                    secondary={[
                      /**
                       * user who are not having the access to view carry over shouldn't be able to see this button as well
                       * Download perm is used in many place so cant use that perm to make this changes work
                       */
                      findings.length && permCheck('all', 'Download') && permCheck('read', "Carry Over")
                        ? {
                          label: 'Findings Report',
                          icon: 'print',
                          onClick: () => toggleModal('showPrintModal', true),
                        }
                        : false,
                    ].filter((_) => _)}
                  />
                )}
              </div>
            )}

            {permCheck('read', 'Findings') && <FindingListing />}
          </div>
        </Route>
      </Switch>

      {/* MODALS */}
      <AddFindingModal
        show={showNewFindingModal}
        hideModal={() => toggleModal('showNewFindingModal', false)}
        inspectionId={inspectionId}
        onAddFinding={onAddFinding}
        defaultDate={defaultCreatedDate}
      />

      {showBulkCompleteModal ? (
        <CompleteFindingsModal
          onSubmit={() => {
            inspectionsCertDue.refetch();
            fetchStatsFuncForOF.refetch();
            toggleModal('showBulkCompleteModal', false);
            setAllSelected(false);
            setSelectedFindings([]);
          }}
          onClose={() => toggleModal('showBulkCompleteModal', false)}
          findings={selectedFindings}
        />
      ) : null}

      <AddNotesModal
        show={showBulkAddNotesModal}
        hideModal={() => toggleModal('showBulkAddNotesModal', false)}
        inspectionId={inspectionId}
        addNotes={bulkAddNotes}
        selectedFindings={selectedFindings}
      />

      {showPrintModal ? (
        <PrintModal
          jobName='findings'
          jobData={{ inspectionId: inspectionId, findingType: filter }}
          statusUpdateKey={inspectionId}
          onClose={() => toggleModal('showPrintModal', false)}
          modalTitle='Findings Report'
          initialFormData={{ reportType: 'all', findingPerPage: '1' }}
          formSchema={(_formData: any, disabled: boolean) => {
            return {
              reportType: {
                controlType: UIControlType.radio,
                validation: {
                  required: true,
                },
                options: [
                  { value: 'all', label: 'All Findings' },
                  { value: 'open', label: 'Open Findings' },
                  { value: 'closed', label: 'Completed Findings' },
                ],
                disabled,
              },
              inspection_label: {
                label: 'Select View',
                controlType: UIControlType.plainText,
                style: {
                  flex: '0 1 20%',
                  alignSelf: 'center',
                  padding: '0.5rem 0.75rem',
                },
              },
              viewType: {
                controlType: UIControlType.select,
                options: [
                  { label: 'List View', value: 'list' },
                  { label: 'Detailed View', value: 'detail' },
                ],
                style: { flex: '0 1 33%' },
                validation: {
                  required: true,
                },
              },
              findingPerPage: {
                controlType: UIControlType.radio,
                options: [
                  { value: '1', label: '1 Finding Per Page' },
                  { value: '2', label: '2 Findings Per Page' },
                  { value: '3', label: '3 Findings Per Page' },
                ],

                className:
                  _formData.viewType !== 'detail' ? 'hidden' : 'show-radio',
              },
            };
          }}
          validateData={false}
        />
      ) : null}

      <SuccessNotification show={showSuccessNotification} />
    </>
  );
};

export default Findings;
